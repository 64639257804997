import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import "react-color-palette/lib/css/styles.css";
import { ReactSketchCanvas } from 'react-sketch-canvas';
import Button from '@material-ui/core/Button';
import FileSaver from 'file-saver';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import ReactGA from "react-ga4";
import Link from '@material-ui/core/Link';


const locationPath = "/tools/sketch-canvas";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "お絵描きサイト";

let width = "100%";
let height = "300px";
let backgroundImage = "https://upload.wikimedia.org/wikipedia/commons/7/70/Graph_paper_scan_1600x1000_%286509259561%29.jpg";
let exportWithBackgroundImage = true;
let strokeWidth = 5;
let strokeColor = "#000000";
let eraseWidth = 8;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    marginTop: '50px',
  },
  margin: {
    padding: '10px',
  },
  colorPickerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Canvas = class extends React.Component {
    
  constructor(props) {
    super(props);

    this.canvas = React.createRef();
  }

  saveFile(base64) {
    let fileName = "life-util.com-sketch-canvas.jpeg"
    FileSaver.saveAs(base64, fileName); 
  }

  render() {

    let itemMargin = 5;
    
    return (
      <div>
        <ReactSketchCanvas
          ref={this.canvas}
          strokeColor={strokeColor}
          width={width}
          height={height}
          backgroundImage={backgroundImage}
          exportWithBackgroundImage={exportWithBackgroundImage}
          strokeWidth={strokeWidth}
          eraserWidth={eraseWidth}
        />

        <Button variant="contained" 
          style={{margin: itemMargin}} 
          onClick={() => {
            this.canvas.current.exportImage("png")
              .then(data => {
                this.saveFile(data);
              })
              .catch(e => {
                //console.log(e);
              });
          }}
        >
          画像としてダウンロード
        </Button>
        
        <br />
          
        <Button variant="contained"  
          style={{margin: itemMargin}} 
          onClick={() => {
            this.canvas.current.eraseMode(false);
          }}
        >ペン</Button>

        <Button variant="contained"  
          style={{margin: itemMargin}} 
          onClick={() => {
            this.canvas.current.eraseMode(true);
          }}
        >消しゴム</Button>
        
        <br />

        <Button variant="contained"  
          style={{margin: itemMargin}} 
          onClick={() => {
            this.canvas.current.undo();
          }}
        >元に戻す</Button>

        <Button variant="contained"  
          style={{margin: itemMargin}} 
          onClick={() => {
            this.canvas.current.redo();
          }}
        >やり直す</Button>

        <br />

        <Button variant="contained"  
          style={{margin: itemMargin}} 
          onClick={() => {
            this.canvas.current.clearCanvas();
          }}
        >クリア</Button>

        <Button variant="contained"  
          style={{margin: itemMargin}} 
          onClick={() => {
            this.canvas.current.resetCanvas();
          }}
        >履歴もクリア</Button>
        
        <br /><br />        

      </div>
    );
  }
}

function SketchCanvas() {
  
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();

  const [widthValue, setWidth] = React.useState(width);
  const [heightValue, setHeight] = React.useState(height);
  const [backgroundImageValue, setBackgroundImage] = React.useState(backgroundImage);
  const [exportWithBackgroundImageValue, setExportWithBackgroundImage] = React.useState(exportWithBackgroundImage);
  const [strokeWidthValue, setStrokeWidth] = React.useState(strokeWidth);
  const [eraseWidthValue, setEraseWidth] = React.useState(eraseWidth);
  //const [strokeColorValue, setStrokeColor] = React.useState(strokeColor);
  
  // Color-picker
  const [color, setColor] = useColor("hex", strokeColor);

  const handleWidthChange = (e) => {
    width = e.target.value;
    setWidth(() => e.target.value)
  }

  const handleHeightChange = (e) => {
    height = e.target.value;
    setHeight(() => e.target.value)
  }
  
  const handleBackgroundImageChange = (e) => {
    backgroundImage = e.target.value;
    setBackgroundImage(() => e.target.value)
  }
  
  const handleExportWithBackgroundImageChange = (e) => {
    exportWithBackgroundImage = e.target.checked;
    setExportWithBackgroundImage(() => e.target.checked)
  }

  const handleStrokeWidthChange = (e) => {
    strokeWidth = e.target.value;
    setStrokeWidth(() => e.target.value)
  }

  const handleEraseWidthChange = (e) => {
    eraseWidth = e.target.value;
    setEraseWidth(() => e.target.value)
  } 

  /**
  const handleStrokeColorChange = (e) => {
    strokeColor = e.target.value;
    setStrokeColor(() => e.target.value)
    console.log("handleStrokeColorChange: " + strokeColor);
  }   */

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="お絵描きサイトです。ブラウザ上のキャンバスに自由に絵を描くことができます。書いた絵は画像として保存できます。キャンバスの幅、高さ、ペンサイズ、消しゴムサイズ、背景画像を設定できます。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="お絵描きサイトです。ブラウザ上のキャンバスに自由に絵を描くことができます。書いた絵は画像として保存できます。キャンバスの幅、高さ、ペンサイズ、消しゴムサイズ、背景画像を設定できます。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <Typography variant="body2" gutterBottom>
            このページは以下のサイトへ移動しました。新サイト<Link href="https://www.oekaki.site/sketch-canvas" target="_blank" rel="noreferrer"><b>おえかきサイト</b></Link>でお楽しみください。<p />
            おえかきサイト：<Link href="https://www.oekaki.site/sketch-canvas" target="_blank" rel="noreferrer">https://www.oekaki.site/sketch-canvas</Link><p />
            2024年9月末頃までは、引き続きこのページでもお楽しみいただけます。<br />
        </Typography>

      <div>
        <Canvas styleClasses={classes} width={widthValue} />
      </div>

      <TextField
        className={classes.margin}
        id="width"
        label="横幅(em/rem/px)"
        defaultValue={widthValue}
        variant="outlined"
        onChange={handleWidthChange}
      />

      <TextField
        className={classes.margin}
        id="height"
        label="高さ(em/rem/px)"
        defaultValue={heightValue}
        variant="outlined"
        onChange={handleHeightChange}
      />

      <br />

      <TextField
        className={classes.margin}
        id="height"
        label="背景画像"
        defaultValue={backgroundImageValue}
        variant="outlined"
        onChange={handleBackgroundImageChange}
      />

      <br />
        
      <FormControlLabel
        className={classes.margin}
        control={
          <Switch
            checked={exportWithBackgroundImageValue}
            onChange={handleExportWithBackgroundImageChange}
            name="exportWithBackgroundImage"
            color="primary"
          />
        }
        label="背景画像を出力する"
      />

      <br />

      <TextField
        className={classes.margin}
        id="strokeWidth"
        label="ペンサイズ"
        defaultValue={strokeWidthValue}
        variant="outlined"
        onChange={handleStrokeWidthChange}
        type="number"
      />

      {/**
      <TextField
        className={classes.margin}
        id="strokeColor"
        label="color"
        defaultValue={strokeColorValue}
        variant="outlined"
        onChange={handleStrokeColorChange}
      /> */}

      <TextField
        className={classes.margin}
        id="eraseWidth"
        label="消しゴムサイズ"
        defaultValue={eraseWidthValue}
        variant="outlined"
        onChange={handleEraseWidthChange}
        type="number"
      />

      <div><Typography variant="caption" gutterBottom>ペンカラーパレット</Typography></div>
      <div className={classes.colorPickerStyle}>
        <ColorPicker width={230} height={150} color={color} 
        onChange={(color) => {
          //console.log(color.hex);
          setColor(color);
          strokeColor = color.hex;
        }}  
      />
      </div>

      <AdSense.Google
      client='ca-pub-6460385026549088'
      slot='1273684807'
      style={{ display: 'block' }}
      layout='in-article'
      format='fluid'
      />

      <AccordionInstruction title="使い方">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            お絵かきボードです。ブラウザ上のキャンバスに自由に絵を描くことができます。書いた絵は画像として保存できます。<br />
            キャンバスの幅、高さ、ペンサイズ、ペンカラー、消しゴムサイズ、背景画像を設定できます。<br />
            
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default SketchCanvas;
